<template>
  <v-app>
    <v-alert
      style="position: fixed; top: 80px; left: 20px; z-index: 99999999999"
      v-if="successAlert"
      dense
      type="primary"
    >
      Configurações atualizadas com sucesso
    </v-alert>
    <v-app-bar
      class="primary-gradient"
      fixed
      dense
    >
      <v-btn
        dark
        icon
        @click="$router.go(-1)"
      >
        <v-icon dense> fas fa-arrow-left </v-icon>
      </v-btn>
      <v-toolbar-title>
        <h4 class="font-weight-bold white--text">Configurações</h4>
      </v-toolbar-title>
    </v-app-bar>
    <v-container class="mt-10">
      <v-row
        justify="space-around"
        class="mt-10"
      >
        <v-col
          cols="12"
          lg="6"
        >
          <v-row dense>
            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="settings.valid_time_budget"
                label="Validade do orçamento"
                rounded
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="settings.fix_value"
                label="Fixo"
                placeholder="R$0,00"
                v-mask="['#,##', '##,##', '###,##']"
                rounded
                dense
                outlined
              />
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 secondary--text">Prazo de entrega</label>
              <ckeditor
                v-model="settings.deadline"
                label="Prazo de entrega"
                rounded
                dense
                outlined
                auto-grow
              />
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 secondary--text"
                >Descrição do pedido</label
              >
              <ckeditor
                v-model="settings.obs_order"
                label="Descrição do pedido"
                rounded
                dense
                outlined
                auto-grow
              />
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 secondary--text"
                >Descrição do orçamento</label
              >
              <ckeditor
                v-model="settings.obs_budget"
                label="Descrição do orçamento"
                rounded
                dense
                outlined
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              align="end"
            >
              <v-btn
                @click="setSettings"
                rounded
                color="primary"
                >Salvar alterações</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <v-expansion-panels
            inset
            focusable
          >
            <v-expansion-panel
              v-for="(item, i) in itemLists"
              :key="i"
            >
              <v-expansion-panel-header>
                {{ item.header }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                style="max-height: 400px"
                class="py-4 overflow-auto"
              >
                <component :is="item.component" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  mixins: [checkProfileRoutePermission],
  components: {
    VolumeTypes: () => import('@/components/settings/VolumeTypes.vue'),
    Tags: () => import('@/components/settings/Tags.vue'),
    CancelReasons: () => import('@/components/settings/CancelReasons.vue'),
    Park: () => import('@/components/settings/Park.vue'),
    OperationNature: () => import('@/components/settings/OperationNature.vue'),
    VendorSegments: () => import('@/components/settings/VendorSegments.vue'),
  },
  data: () => ({
    settings: {},
    successAlert: false,
    itemLists: [
      { header: 'Tipo (Volume)', component: 'volume-types' },
      { header: 'Tag', component: 'tags' },
      { header: 'Motivos de cancelamento', component: 'cancel-reasons' },
      { header: 'Estacionamento', component: 'park' },
      { header: 'Natureza de operação', component: 'operation-nature' },
      { header: 'Segmentos do fornecedor', component: 'vendor-segments' },
    ],
  }),
  methods: {
    getSettings() {
      this.$api
        .get('settings', {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.settings = res.data.data
          this.$store.commit('setFixedValue', this.settings.fix_value)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setSettings() {
      let fixed_value = this.settings.fix_value
      if (fixed_value.includes(',')) {
        fixed_value = parseFloat(fixed_value.replace(',', '.'))
      }
      this.$api
        .post('setting/update', this.settings)
        .then(() => {
          this.successAlert = true

          setTimeout(() => {
            this.successAlert = false
          }, 2000)

          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Configuração cadastrado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })

          this.$store.commit('setFixedValue', this.settings.fix_value)
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar esta configuração',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  mounted() {
    this.getSettings()
  },
}
</script>
